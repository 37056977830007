<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />

    <APILoader v-if="loading" :loading="loading" class="align-middle"></APILoader>
    <div v-if="locations.length > 0">
      <div class="row align-items-center">
        <div class="col-md-6" v-if="isRestaurantOwner()">
          <div class="d-flex align-items-center">
            <img :src="currentUser.restaurant.logo == null ? imageUrl : this.assets_url + currentUser.restaurant.logo"
              class="widthImg border-right pe-2 me-2">
            <h5 class="px-2 my-0">{{ currentUser.restaurant.name }}</h5>
          </div>
        </div>
        <div class="col-md-6" v-else>
          <router-link to="/employee-home">
            <button class="btn primaryColorBg text-white"><i class="fa fa-key mx-2"></i>Switch to Employee</button>
          </router-link>
        </div>
        <div class="col-md-4 mb-3 mx-auto me-0">
          <div class="d-flex align-items-center gap-2">
            <div class="icon-wrapper mt-3 pt-1">
            </div>
            <div class="form-field__wrapper w-100">
              <label>Select Location</label>

              <div class="border input-group rounded shadow">
                <div class="bg-white border-0 input-group-text">
                  <img src="@/assets/images/profile-manager/icon-11.png" width="21" alt="" />
                </div>
                <select v-model="location_id" id="emp_job_type" class="form-select border-0" @change="fetchStats()">
                  <option :value="location.id" v-for="(location, index) in locations" :key="index">
                    {{ location.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="new__boxes d-grid gap-2 grid_inline">
            <div class="justify-content-center card rounded box-shadow px-3 py-3">
              <router-link to="/create-location" class="">
                <div class="innerCard d-flex align-items-center">
                  <img src="@/assets/images/dashboard/location.svg" width="40px">
                  <h2 class="text-dark f-16 pt-1 ms-3 fw-bold">Add Location</h2>
                </div>
              </router-link>
            </div>
            <div class="justify-content-center card rounded box-shadow px-3 py-3">
              <router-link to="/workarea/create" class="">
                <div class="innerCard d-flex align-items-center">
                  <img src="@/assets/images/dashboard/work-area.svg" width="40px">
                  <h2 class="text-dark f-16 pt-1 ms-3 fw-bold">Add Work Area</h2>
                </div>
              </router-link>
            </div>
            <div class="justify-content-center card rounded box-shadow px-3 py-3">
              <router-link to="/workarea/create" class="">
                <div class="innerCard d-flex align-items-center">
                  <img src="@/assets/images/dashboard/work-area.svg" width="40px">
                  <h2 class="text-dark f-16 pt-1 ms-3 fw-bold">Add Role</h2>
                </div>
              </router-link>
            </div>
            <div class="justify-content-center card rounded box-shadow px-3 py-3">
              <router-link to="/create-shift" class="">
                <div class="innerCard d-flex align-items-center">
                  <img src="@/assets/images/dashboard/create-shift.svg" width="40px">
                  <h2 class="text-dark f-16 pt-1 ms-3 fw-bold">Create Shift</h2>
                </div>
              </router-link>
            </div>
            <div class="justify-content-center card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/shifts-roster', query: { tab: 'vacant_shifts' } }" class="">
                <div class="innerCard d-flex align-items-center">
                  <img src="@/assets/images/dashboard/vacant-shift.svg" width="40px">
                  <h2 class="text-dark f-16 pt-1 ms-3 fw-bold">Vacant Shifts</h2>
                </div>
              </router-link>
            </div>
            <div class="justify-content-center card rounded box-shadow px-3 py-3">
              <router-link to="/business-owner-shift-listing#ApplyForLeave" class="">
                <div class="innerCard d-flex align-items-center">
                  <img src="@/assets/images/dashboard/leave.svg" width="40px">
                  <h2 class="text-dark f-16 pt-1 ms-3 fw-bold">Leave Requests</h2>
                </div>
              </router-link>
            </div>
            <div class="justify-content-center card rounded box-shadow px-3 py-3">
              <router-link to="/calendar" class="">
                <div class="innerCard d-flex align-items-center">
                  <img src="@/assets/images/dashboard/vacant.svg" width="40px">
                  <h2 class="text-dark f-16 pt-1 ms-3 fw-bold">Vacant Employees</h2>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="d-grid gap-2 grid_inline">
            <div class="card rounded box-shadow px-3 py-3">
              <router-link to="/locations">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/location_icon.png">
                  <h2 class="text-dark f-30 pt-1">{{ locations.length }}</h2>
                  <p class="text-dark mb-2">Total Locations</p>
                  <span class="secondaryColor">Registered Locations</span>
                </div>
              </router-link>
            </div>
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/business-owner-shift-listing', hash: 'Accepted' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/active_shifts_icon.png">
                  <h2 class="text-dark f-30 pt-1">{{ statCounts.active_Shifts }}</h2>
                  <p class="text-dark mb-2">Total Active Shifts</p>
                  <span class="secondaryColor">This Week</span>
                </div>
              </router-link>
            </div>
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/shifts' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/bonus_shifts.png">
                  <h2 class="text-dark f-30 pt-1">{{ bonus_shifts.length }}</h2>
                  <p class="text-dark mb-2">Bonus Shifts</p>
                  <span class="secondaryColor">This Week</span>
                </div>
              </router-link>
            </div>
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/calendar' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/upcomming_week.png">
                  <h2 class="text-dark f-30 pt-1">{{ statCounts.progress_shift_counts }}</h2>
                  <p class="text-dark mb-2">Upcoming Shifts</p>
                  <span class="secondaryColor"> This Week</span>
                </div>
              </router-link>
            </div>
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/calendar' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/upcomming_week.png">
                  <h2 class="text-dark f-30 pt-1">{{ statCounts.current_month_shifts_count }}</h2>
                  <p class="text-dark mb-2">Total Shifts</p>
                  <span class="secondaryColor">This Month</span>
                </div>
              </router-link>
            </div>
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/business-owner-shift-listing', hash: 'ApplyForLeave' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/leaves_apply.png">
                  <h2 class="text-dark f-30 pt-1">{{ statCounts.leavesApplied }}</h2>
                  <p class="text-dark mb-2">Leaves Applied</p>
                  <span class="secondaryColor">This Week</span>
                </div>
              </router-link>
            </div>
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/shifts-roster' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/open_shifts.png">
                  <h2 class="text-dark f-30 pt-1">{{ statCounts.open_shifts_count_for_month + statCounts.released_shifts_count_in_month }}</h2>
                  <p class="text-dark mb-2">Vacant Shifts</p>
                  <span class="secondaryColor">This Month</span>
                </div>
              </router-link>
            </div>
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/shifts-roster' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/open_shifts.png">
                  <h2 class="text-dark f-30 pt-1">{{ statCounts.open_shifts_count + statCounts.released_and_rejected_in_week}}</h2>
                  <p class="text-dark mb-2">Vacant Shifts</p>
                  <span class="secondaryColor">This Week</span>
                </div>
              </router-link>
            </div>
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/business-owner-shift-listing', hash: 'Released' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/dropped_shifts.png">
                  <h2 class="text-dark f-30 pt-1">{{ statCounts.released_shifts }}</h2>
                  <p class="text-dark mb-2">Dropped Shifts</p>
                  <span class="secondaryColor">This Week</span>
                </div>
              </router-link>
            </div>

            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/team-member-record', hash: 'Released' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/registered_users.png">
                  <h2 class="text-dark f-30 pt-1">{{ statCounts.registered_users }}</h2>
                  <p class="text-dark mb-2">Registered Users</p>
                  <span class="secondaryColor">This Week</span>
                </div>
              </router-link>
            </div>

            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/team-member-requests', hash: 'Released' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/wait_for_approval.png">
                  <h2 class="text-dark f-30 pt-1">{{ statCounts.wait_for_approval }}</h2>
                  <p class="text-dark mb-2">New Employee Requests</p>
                  <span class="secondaryColor">Total Requests</span>
                </div>
              </router-link>
            </div>

          </div>
        </div>
      </div>

      <div class="row justify-content-between">
        <div class="col-md-6 mb-3">
          <div class="d-flex">
            <div v-for="(tab, index) in tabs" :key="index"> 
              <button type="button" class="btn" :class="{'btn-pink': tab.isActive}"  @click="checkActiveTab(index)">{{tab.name}}</button>
            </div>
          </div>
        </div>
        
        <div class="col-md-6 mb-3 text-end" v-if="tabs[1].isActive">
          <div class="d-flex flex-md-row flex-column justify-content-md-end justify-content-center search_field">
            <div class="d-block me-md-3 me-0 mb-md-0 mb-3">
              <span>Filter By: </span>
              <button  class="fw-bold  btn border border-pink  me-2 " 
              :class="{'btn-pink': (tabs[1].isActive || employeeClicked) && !vacantEmployeeClicked}"
              @click="showVacantEmployeeView('employee')"
              >
                Schedule Employees
              </button>
              <button  class="fw-bold  btn border border-pink  me-2 " 
              :class="{'btn-pink': vacantEmployeeClicked && !employeeClicked}"
              @click="showVacantEmployeeView('vacant_employee')"
              >
                Vacant Employees
              </button>
            </div>
            <form @submit.prevent="searchVacantEmployee" v-if="vacantEmployeeClicked">
            <input v-model="searchEmployee" type="text" name="search" id="search" placeholder="Search Employee" class="ms-md-0 ms-auto mr-0 form-control" >
            </form>
          </div>
        </div>
        <div class="col mb-3 text-end" v-if="currentTab == 'calendar'">
          <button @click="toggleFilters"  class="fw-bold btn border border-pink text-pink rounded me-2 filter_btn">
            Filters
          </button>
        </div>

      </div>
      
      <div class="card" v-if="currentTab == 'schedule_shifts'">
        <h5 class="mt-3 ms-3">Schedule Shift</h5>
          <allScheduleShiftsListing
                      
                      ref="scheduleShiftsRef"
                      :schedulePropFiltelrs="table_data"
                      :showExportBtn="false"
                    />
      </div>
                    
      <div class="admin_calendar pt-4">
        <calendar_component ref="changeViewAndToggleFilter" :checkTab="tabValue"/>
      </div>
      
      <div class="card" v-if="currentTab == 'vacant_shifts'">
        <h5 class="mt-3 ms-3">Vacant Shift</h5>
        <allVacantShiftsListing
                      ref="vacantShiftsRef"
                      :vacantPropFiltelrs="table_data"
                      :showExportBtn="false"
                      :showViewAllBtn="true"
                    />
        </div>
      <div class="row">
        <div class="col-xl-6 col-12 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex">
                <h4 class="card-title">Pending Shift Pickup Requests</h4>
                <i class="blink fa fa-exclamation-circle secondaryColor mx-2 f-18" v-if="pending_shifts.length > 0"></i>
              </div>
              <div class="table-responsive table-height">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Employee</th>
                      <th>Day</th>
                      <th>Location</th>
                      <th>Position</th>
                      <th>Timing</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(shiftTimeline, index ) in pending_shifts" :key="index">
                      <td>{{ getSafe(() => shiftTimeline.shift.name) }}</td>
                      <td>
                        <p>{{ getSafe(() => shiftTimeline.employee.user.name) }}</p>
                      </td>
                      <td>{{ getSafe(() => shiftTimeline.shift.interval) }}</td>
                      <td>{{ getSafe(() => shiftTimeline.shift.location.name) }}</td>
                      <td>{{ getSafe(() => shiftTimeline.area_role.name) }}</td>
                      <td>{{ DateWithMonthWithoutYear(shiftTimeline.from) }} - {{
                        DateWithMonthWithoutYear(shiftTimeline.to) }}</td>
                    </tr>
                    <tr v-if="pending_shifts.length == 0">
                      <td colspan="6">
                        <AlertWidget class="mt-4" text="No Record Found"></AlertWidget>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <APILoader v-if="pendingshiftsLoading" :loading="pendingshiftsLoading" class="align-middle"></APILoader>
              </div>
              <div class="seeMore pt-3 text-center" v-if="pending_shifts.length > 0">
                <router-link :to="{ path: '/business-owner-shift-listing', hash: 'Pending' }">
                  View All</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-12 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex">
                <h4 class="card-title">Pending Shift Release Requests</h4>
                <i class="blink fa fa-exclamation-circle secondaryColor mx-2 f-18"
                  v-if="releaseRequest_shifts.length > 0"></i>
              </div>
              <div class="table-responsive table-height">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Employee</th>
                      <th>Day</th>
                      <th>Location</th>
                      <th>Position</th>
                      <th>Timing</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(shiftTimeline, index ) in releaseRequest_shifts" :key="index">
                      <td>{{ shiftTimeline.shift.name }}</td>
                      <td>
                        <p>{{ getSafe(() => shiftTimeline.employee.user.name) }}</p>
                      </td>
                      <td>{{ shiftTimeline.shift.interval }}</td>
                      <td>{{ getSafe(() => shiftTimeline.shift.location.name) }}</td>
                      <td>{{ getSafe(() => shiftTimeline.area_role.name) }}</td>
                      <td>{{ DateWithMonthWithoutYear(shiftTimeline.from) }} - {{
                        DateWithMonthWithoutYear(shiftTimeline.to) }}</td>
                    </tr>
                    <tr v-if="releaseRequest_shifts.length == 0">
                      <td colspan="6">
                        <AlertWidget class="mt-4" text="No Record Found"></AlertWidget>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <APILoader v-if="releaseRequestShiftsLoading" :loading="releaseRequestShiftsLoading" class="align-middle">
                </APILoader>
              </div>
              <div class="seeMore pt-3 text-center" v-if="releaseRequest_shifts.length > 0">
                <router-link :to="{ path: '/business-owner-shift-listing', hash: 'ApplyForLeave' }">
                  View All</router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 col-12 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <h4 class="card-title">Shifts In progress</h4>
              <div class="table-responsive table-height">
                <table class="table mb-0">

                  <thead>
                    <tr>
                      <!-- <th>Sr. #</th> -->
                      <th>Name</th>
                      <th>Day</th>
                      <th>Time</th>
                      <th>Lock Day</th>
                      <!-- <th>Assign to</th> -->
                      <th>Location</th>
                      <th>Position</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(shift, index) in shift_inprogress" :key="index">
                      <!-- <th scope="row">{{index+1}}</th> -->
                      <td>{{ shift.name }}</td>
                      <td>{{ shift.interval }}</td>
                      <td>{{ DateWithMonthWithoutYear(shift.shift_time_slots[0].from) }} -
                        {{ DateWithMonthWithoutYear(shift.shift_time_slots[0].to) }}</td>
                      <td> {{ getSafe(() => shift.location.lock_date) }}</td>
                      <!-- <td>
                        <span class="userImages">
                          <span class="inline_img">
                            <img src="@/assets/images/dashboard/manager.png" alt=""
                              class="h-100 w-100 fit_cover border-0 rounded-circle d-none">
                          </span>
                          <span class="inline_img"></span>
                          <span class="inline_img m-0"></span>
                          <span class="ms-2">8+</span>
                        </span>
                      </td> -->
                      <td>{{ shift.location.name }}</td>
                      <td>{{ rolesToString(shift.area_roles) }}</td>
                    </tr>
                    <tr v-if="shift_inprogress.length == 0">
                      <td colspan="6">
                        <AlertWidget class="mt-4" text="No Record Found"></AlertWidget>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <APILoader v-if="inprogressLoading" :loading="inprogressLoading" class="align-middle"></APILoader>
              </div>
              <div class="seeMore pt-3 text-center" v-if="inProgressShifts_nextPageUrl">
                <a href="javascript:void(0)" @click="seeMoreProgressShifts">See More</a>
                <!-- <router-link class="primaryColor " :to="{ name: 'shift_listing' }">See More</router-link> -->
              </div>

            </div>
          </div>
        </div>
        <div class="col-xl-6 col-12 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <h4 class="card-title">Upcoming Week Shifts</h4>
              <div class="table-responsive table-height">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Day</th>
                      <th>Time</th>
                      <th>Lock Day</th>
                      <th>Location</th>
                      <th>Position</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(shift, index) in new_shifts" :key="index">
                      <!-- <th scope="row">{{index+1}}</th> -->
                      <td>{{ shift.name }}</td>
                      <td>{{ shift.interval }}</td>
                      <td>{{ DateWithMonthWithoutYear(shift.shift_time_slots[0].from) }} -
                        {{ DateWithMonthWithoutYear(shift.shift_time_slots[0].to) }}</td>
                      <td> {{ getSafe(() => shift.location.lock_date) }}</td>
                      <td> {{ getSafe(() => shift.location.name) }}</td>
                      <td>{{ getSafe(() => rolesToString(shift.area_roles)) }}</td>
                    </tr>
                    <tr v-if="new_shifts.length == 0">
                      <td colspan="6">
                        <AlertWidget class="mt-4" text="No Record Found"></AlertWidget>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <APILoader v-if="newShiftsLoading" :loading="newShiftsLoading" class="align-middle"></APILoader>
              </div>
              <div class="seeMore pt-3 text-center" v-if="newShifts_nextPageUrl">
                <!-- <router-link class="primaryColor " :to="{ name: 'shift_listing' }">See More</router-link> -->
                <a href="javascript:void(0)" @click="seeMoreNewShifts">See More</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-12 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <h4 class="card-title">Bonus Shifts</h4>
              <div class="table-responsive table-height">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Day</th>
                      <th>Location</th>
                      <th>Position</th>
                      <th>Timing</th>
                      <th>Bonus</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(shift, index ) in bonus_shifts" :key="index">
                      <td>{{ shift.name }}</td>
                      <td>{{ shift.interval }}</td>
                      <td>{{ getSafe(() => shift.location.name) }}</td>
                      <td>{{ getSafe(() => rolesToString(shift.area_roles)) }}</td>
                      <td>{{ DateWithMonthWithoutYear(shift.shift_time_slots[0].from) }} - {{
                        DateWithMonthWithoutYear(shift.shift_time_slots[0].to) }}</td>
                      <td>${{ shift.bonus }}</td>
                    </tr>
                    <tr v-if="bonus_shifts.length == 0">
                      <td colspan="6">
                        <AlertWidget class="mt-4" text="No Record Found"></AlertWidget>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <APILoader v-if="bonusshiftsLoading" :loading="bonusshiftsLoading" class="align-middle"></APILoader>
              </div>
              <div class="seeMore pt-3 text-center" v-if="bonusShifts_nextPageUrl">
                <a href="javascript:void(0)" @click="seeMoreBonusShifts">See More</a>
              </div>
            </div>
          </div>
        </div>

        <!--Vacant shifts After Lock Date-->
        <div class="col-xl-6 col-12 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <h4 class="card-title">Vacant Shifts After Lock Date</h4>
              <div class="table-responsive table-height">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Interval</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(shift, index) in vacantShiftsAfterLockDate" :key="index">
                      <td>{{ shift.name }}</td>
                      <td>{{ DateWithMonthName(shift.from) }}</td>
                      <td>{{ DateWithMonthName(shift.to) }}</td>
                      <td>{{ shift.interval }}</td>
                      <td>{{ shift.shift_type }}</td>
                    </tr>
                    <tr v-if="vacantShiftsAfterLockDate.length == 0">
                      <td colspan="5">
                        <AlertWidget class="mt-4" text="No Record Found"></AlertWidget>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!--End Of Vacant Shifts After Lock Date-->

        <div class="col-xl-6 col-12 mb-4" v-if="isRestaurantOwner()">
          <div class="card h-100">
            <div class="card-body">
              <h4 class="card-title">Managers</h4>
              <div class="table-responsive table-height">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Member Name</th>
                      <th>Contact</th>
                      <th>User Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(manager, index) in managers" :key="index">
                      <td>{{ manager.name }}</td>
                      <td>{{ manager.phone }}</td>
                      <td>{{ manager.email }}</td>
                    </tr>
                    <tr v-if="managers.length == 0">
                      <td colspan="3">
                        <AlertWidget class="mt-4" text="No Record Found"></AlertWidget>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <APILoader v-if="managerLoading" :loading="managerLoading" class="align-middle"></APILoader>
              </div>
              <div class="seeMore pt-3 text-center" v-if="managers_nextPageUrl">
                <a href="javascript:void(0)" @click="seeMoreManagers">See More</a>
                <!-- <router-link class="primaryColor " :to="{ name: 'managers_listing' }">See More</router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no_location">
      <!-- <AlertWidget class="mt-4" text="No Location Found"></AlertWidget> -->
      <div class="text-center">
        <img src="@/assets/images/dashboard/no_loc.png" class="mb-3">
        <h3 class="poppins">No Location Found!</h3>
        <p class="text-muted poppins"><router-link to="/create-location"><span
              class="f-16 fw-bold text-decoration-underline">Create Location</span></router-link> to proceed further.</p>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import AlertWidget from "@/components/general/AlertWidget";
import calendar_component from "@/views/pages/admin/dashboard/calendar/calendar.vue";
import imageUrl from "@/assets/images/dashboard/default-logo.png";
import allScheduleShiftsListing from "../shift/components/allScheduleShiftsListing.vue";
import allVacantShiftsListing from "../shift/components/allVacantShiftsListing.vue";
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    AlertWidget,
    calendar_component,
    allScheduleShiftsListing,
    allVacantShiftsListing
  },
  data() {
    return {
      tabs: [
        {name: 'Calendar View',value:"calendar" ,isActive: true},
        {name: 'Employees View', value:"employee",sActive: false},
        {name: 'Vacant Shifts',value:"vacant_shifts" ,isActive: false},
        {name: 'Schedule Shifts',value:"schedule_shifts" ,isActive: false},
      ],
      employeeClicked:false,
      vacantEmployeeClicked:false,
      searchEmployee:null,
      currentTab:'calendar',
      isClick:false,
      title: "Dashboard",
      items: [
        {
          text: "Shift Fish",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      loading: false,
      statCounts: {},
      tabValue: 'calendar',
      //Vacant Shifts After Lock Date
      vacantShiftsAfterLockDate:[],
      //updated Dashboard Api's
      locations: [],
      location_id: null,
      new_shifts_status: 'New_Shifts',
      inprogress_shifts_status: 'Shifts_Inprogress',
      bonus_shifts_status: 'Bonus_Shifts',
      pending_shifts_status: 'Pending',
      releaseRequest_status: 'Apply For Leave',
      new_shifts: [],
      shift_inprogress: [],
      bonus_shifts: [],
      pending_shifts: [],
      releaseRequest_shifts: [],
      managers: [],
      newShiftsLoading: false,
      inprogressLoading: false,
      bonusshiftsLoading: false,
      managerLoading: false,
      pendingshiftsLoading: false,
      releaseRequestShiftsLoading: false,
      table_data: {
        new_shifts_size: 3,
        bonus_shifts_size: 3,
        inprogress_shifts_size: 3,
        pending_shifts_size: 3,
        releaseRequest_shifts_size: 3,
        draw: 0,
        length: 3,

      },
      newShifts_nextPageUrl: null,
      bonusShifts_nextPageUrl: null,
      inProgressShifts_nextPageUrl: null,
      managers_nextPageUrl: null,
    };
  },

  created() {
    this.imageUrl = imageUrl
  },
  mounted() {
    this.fetchLocations();
  },

  methods: {
    
    checkActiveTab(index){
    this.tabs.forEach((tab) => {
      tab.isActive = false;
    });

    this.tabs[index].isActive = true;
    this.currentTab=this.tabs[index].value;
    if(this.currentTab=='employee'){this.vacantEmployeeClicked=false}
    this.tabValue=this.currentTab;
    // this.$refs.changeViewAndToggleFilter.changeViewAs(this.currentTab);
    },
    showVacantEmployeeView(view)
    {
      if(view=='employee'){this.employeeClicked=true; this.vacantEmployeeClicked=false}
      if(view=='vacant_employee'){this.employeeClicked=false; this.vacantEmployeeClicked=true}
      this.$refs.changeViewAndToggleFilter.changeViewAs(view);
    },
    searchVacantEmployee(){
      this.$refs.changeViewAndToggleFilter.changeViewAs('vacant_employee');
      this.$refs.changeViewAndToggleFilter.searchVacantEmployee(this.searchEmployee);
      this.employeeClicked=false; 
      this.vacantEmployeeClicked=true;
      this.searchEmployee="";
    },
    //Show/Hide Filters Menu
    toggleFilters(){
      this.$refs.changeViewAndToggleFilter.isClick=!this.isClick;
      this.isClick=!this.isClick;
    },
    fetchLocations() {
      this.loading = true;
      this.$axios.get('/restaurant/location/index')
        .then(response => {
          let data = response.data.payload;
          if (data.length > 0) {
            this.locations = data;
            this.location_id = this.locations[0].id;
            this.statsCount();

            this.fetchNewShifts();
            this.fetchInprogressShifts();
            this.fetchBonusShifts();

            this.fetchVacantShiftsAfterLockDate();
            this.fetchManagers();
            this.loading = false;
          }
        }).catch(error => {
          this.handleErrorResponse(error.response, "error");
        }).finally(() => {
          this.loading = false;
        });
    },

    statsCount() {
      this.loading = true;
      this.$axios.get('/dashboard/stats-counts', { params: { location_id: this.location_id } }).then(response => {
        let data_count = response.data.payload;
        this.statCounts = data_count;
        let shiftTimeLineData=data_count[0].shiftTimeLines.original.payload;
        this.pending_shifts=shiftTimeLineData.pending.data;
        this.releaseRequest_shifts=shiftTimeLineData.apply_for_leave.data;
        
        if (data_count.length > 0) {
          this.statCounts = data_count[0];
        } else {
          this.statCounts = {};
        }
        this.loading = false;
      })
        .catch(error => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(() => (this.loading = false));
    },
    fetchNewShifts(url) {
      url =url??"/dashboard/index";
      this.newShiftsLoading = true;
      this.$axios
        .get(url, { params: { location_id: this.location_id, status: this.new_shifts_status, page_size: this.table_data.new_shifts_size } })
        .then((response) => {
          let data = response.data.payload;
          this.new_shifts.push(...data.data);
          this.newShifts_nextPageUrl = data.next_page_url;
          this.newShiftsLoading = false;
        })
        .catch(error => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(() => (this.newShiftsLoading = false));
    },
    fetchInprogressShifts(url) {
      url =url??"/dashboard/index";
      this.inprogressLoading = true;
      this.$axios
        .get(url, { params: { location_id: this.location_id, status: this.inprogress_shifts_status, page_size: this.table_data.inprogress_shifts_size } })
        .then((response) => {
          let data = response.data.payload;
          // this.shift_inprogress = data.data;
          this.shift_inprogress.push(...data.data);
          this.inProgressShifts_nextPageUrl = data.next_page_url;
          this.inprogressLoading = false;
        })
        .catch(error => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(() => (this.inprogressLoading = false));
    },
    fetchBonusShifts(url) {
      url = url??"/dashboard/index";
      this.bonusshiftsLoading = true;
      this.$axios
        .get(url, { params: { location_id: this.location_id, status: this.bonus_shifts_status, page_size: this.table_data.bonus_shifts_size } })
        .then((response) => {
          let data = response.data.payload;
          // this.bonus_shifts = data.data;
          this.bonus_shifts.push(...data.data);
          this.bonusShifts_nextPageUrl = data.next_page_url;
          this.bonusshiftsLoading = false;
        })
        .catch(error => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(() => (this.bonusshiftsLoading = false));
    },
    fetchPendingShifts() {
      let url = "/shift/employee/index";
      this.pendingshiftsLoading = true;
      this.$axios
        .get(url, { params: { location_id: this.location_id, status: this.pending_shifts_status, length: this.table_data.pending_shifts_size } })
        .then((response) => {
          let data = response.data.payload;
          this.pending_shifts = data.data;
          this.pendingshiftsLoading = false;
        })
        .catch(error => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(() => (this.pendingshiftsLoading = false));
    },
    fetchReleaseRequestShifts() {
      let url = "/shift/employee/index";
      this.releaseRequestShiftsLoading = true;
      this.$axios
        .get(url, { params: { location_id: this.location_id, status: this.releaseRequest_status, length: this.table_data.releaseRequest_shifts_size } })
        .then((response) => {
          let data = response.data.payload;
          this.releaseRequest_shifts = data.data;
          // this.releaseRequest_shifts.push(...data.data);
          this.releaseRequestShiftsLoading = false;
        })
        .catch(error => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(() => (this.releaseRequestShiftsLoading = false));
    },
    fetchManagers(url) {
      url = url ?? "/employee/manager/index";
      this.managerLoading = true;
      this.table_data.draw++;
      this.$axios.get(url, { params: { paginated: true, length: this.table_data.length, draw: this.table_data.draw, location_id: this.location_id } })
        .then(response => {
          let data = response.data.payload.paginated;
          // this.managers=data.paginated.data;
          this.managers.push(...data.data);
          this.managers_nextPageUrl = data.next_page_url;
          this.managerLoading = false
        }).catch(error => {
          this.handleErrorResponse(error.response, "error");
        }).finally(() => {
          this.managerLoading = false;
        });
    },
    seeMoreNewShifts() {
      this.fetchNewShifts(this.newShifts_nextPageUrl);
    },
    seeMoreBonusShifts() {
      this.fetchBonusShifts(this.bonusShifts_nextPageUrl);
    },
    seeMoreProgressShifts() {
      this.fetchInprogressShifts(this.inProgressShifts_nextPageUrl);
    },
    seeMoreManagers() {
      this.fetchManagers(this.managers_nextPageUrl);
    },

    
    fetchStats() {
      this.new_shifts = [];
      this.bonus_shifts = [];
      this.shift_inprogress = [];
      this.managers = [];
      this.vacantShiftsAfterLockDate=[];
      this.statsCount();
      
      this.fetchNewShifts();
      this.fetchInprogressShifts();
      this.fetchBonusShifts();
      
      this.fetchManagers();
      this.fetchVacantShiftsAfterLockDate();
    },

    rolesToString(areaRoles) {
      let name_array = areaRoles.map((role) => {
        return role.name;
      });
      let string = name_array.toString();

      return string;
    },
    fetchVacantShiftsAfterLockDate(){
      this.$axios.get('/shift/shifts-after-lockdate',{ params: { location_id: this.location_id }})
      .then((response)=>{
        this.vacantShiftsAfterLockDate=response.data.payload;
      }).catch(error => {
          this.handleErrorResponse(error.response, "error");
        });
    }
  },
};
</script>

<style scoped>
.widthImg {
  height: 40px;
  object-fit: contain;
}

.table-height {
  height: 180px;
  overflow-y: auto;
}

.no_location {
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.blink {
  animation: blink 2s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}
</style>