<script>
    import { required } from "vuelidate/lib/validators";
    import VueMultiselect from "@/components/general/vue-multiselect";
    import Swal from "sweetalert2";
    export default {
        data() {
            return {
                assignShiftModal:false,
                employee_id: null,
                selectedEmployee:null,
                employees : {
                    data : [],
                    selected : [],
                    VueMultiselectOpened: false,
                    loading: false,
                    nextPageUrl: null,
                },
                shiftTimeSlot:{type:null,id:null},
                area_role_id: null,
                counts:null,
                shiftsForRole :{},
                location : {},
                shiftRoles : {
                    area_roles: [],
                },
                processing:false,
                length : 6,
                fromCalendar:false
            };
        },
        components: {
            VueMultiselect,
        },
        validations:{
            employee_id: { required },
            area_role_id:{ required },
            shiftTimeSlot: { 
                id : {required } 
            },
        },
        props: {
            shift:{
                type: Object,
                default: () => {
                    return {}
                },
            },

            id:{
                type: String, 
                default: "modal-assignShift",
            }
        },

        watch: {
            shift: {
                handler(newValue){
                    if(newValue){
                        this.shiftsForRole = {...newValue};
                        this.location = {...newValue?.location};
                    }
                },
                deep: true,
                immediate: true,
            },
            area_role_id:{
                handler:function(newValue){
                    if(newValue){
                        this.fetchShiftSlotByAreaId();
                        // this.fetchMembersByRole();
                    }
                }
            },
            selectedEmployee:{
                handler:function(newValue){
                    if(newValue != null){
                        if(this.fromCalendar)
                        {
                            this.employee_id=newValue.employee.id
                        }
                        else{
                            this.employee_id = newValue.id
                        }
                    }
                }
            },
            assignShiftModal:{
                handler:function(newValue){
                    if(newValue == false){
                    this.selectedEmployee = null;
                    this.employee_id = null;
                    this.area_role_id = null;
                    this.shiftTimeSlot.id = null;
                    }
                }
            },
        },
        computed : {
            loadMoreEmployees() {
                return !!this.employees.nextPageUrl;
            },
        },
        methods: {
            confirmOverTimeShift() {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You want to assign shifts to this user, the user has already scheduled 40 hours in a week.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#70C213",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, Accept",
                }).then((result) => {
                    if (result.value) {
                        this.submitShift();
                    }
                });
            },

            checkCounts()
            {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.$axios
                .get("counts-stats/index", {
                    params: {employee_id : this.employee_id, shift_id: this.shift.id }
                })
                .then((response) => {
                    let totalHours = response.data.payload;
                    if(totalHours > 40)
                    {
                        this.confirmOverTimeShift();
                    }else{
                        this.submitShift();
                    }
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                });
            },

            submitShift()
            {   
                if (this.processing) {
                    return;
                }
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.processing = true;
                this.$axios.post('shift/employee/select-shift', {
                    employee_id:this.employee_id,
                    shift_id:this.shift.id, 
                    area_role_id:this.area_role_id,
                    slot_id:this.shiftTimeSlot.id,
                    status:'Accepted'
                })
                .then(response => {
                    let data = response.data;
                    this.assignShiftModal=false;
                    this.processing = false;
                    this.triggerSwal('Shift Assigned Successfully', "success");
                })
                .catch(error => {
                    this.handleErrorResponse(error.response, "error");
                })
                .finally(() => {
                    this.processing = false;
                    this.assignShiftModal=false;
                    this.shiftTimeSlot ={};
                    this.area_role_id = null;
                    this.employee_id = null;
                    this.fromCalendar=false;
                    this.$v.$reset();
                });
            },
            fetchMembersByRole()
            {
                let url =  '/employee/team-member/area-role';
                if (this.employees.nextPageUrl) {
                    url = this.employees.nextPageUrl;
                }
                this.$axios.get(url,{
                    params: {slot_id:this.shiftTimeSlot.id, location_id:this.location.id , area_role_id : this.area_role_id , length : this.length , employee_status:'active_employee'}}).then(response => {
                    let result = response.data.payload;
                    if (url == "/employee/team-member/area-role") this.employees.data = [];
                    result.data.forEach((employee) => {
                        this.employees.data.push({id : employee.id , name : employee.user.name , email : employee.user.email,label:employee.user.name +" - "+ employee.user.email})
                    });
                    this.employees.nextPageUrl = result.next_page_url;
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                });
            },
            fetchShiftSlotByAreaId() {
                this.$axios.get("shift-time-slots/area-role",{
                    params:{
                        shift_id:this.shift.id,
                        area_role_id:this.area_role_id,
                        empty_slot:true
                        }}

                ).then((response) => {
                let data = response.data.payload;
                this.shiftsForRole.shift_time_slots = data;

                }).catch(function (error){
                this.handleErrorResponse(error.response, "error");
                });

               
            },
            fetchEmployeesOnReachingEnd() {
                this.fetchMembersByRole();
            },

        },

        middleware: "authentication",
    };
</script>

<template>
    <div>
        <b-modal :id="id"  scrollable v-model="assignShiftModal" content-class="shadow" size="lg" title="Assign Shift" title-class="text-black poppins font-18" hide-footer body-class="p-3 pb-5" no-close-on-backdrop no-close-on-esc hide-header-close >
                <b-form inline>
                    <div class="row">
                        <div class="col-md-6">
                            <span class="text-muted" for="inline-form-input-name">Location</span>
                            <input type="text"  class="form-control mt-1" v-model="location.name" disabled>
                        </div>
                        
                        <div class="col-md-6">
                            <span class="text-muted" for="inline-form-input-username">Role/Position</span>
                            <select  v-model="area_role_id" class="form-select mt-1"
                            :class="{'is-invalid': $v.area_role_id.$error}">
                                <option :value="role.id" v-for="(role,index) in shiftsForRole.area_roles" :key="index" >{{role.name}}</option>
                            </select>
                            <div v-if="$v.area_role_id.$error" class="invalid-feedback" >
                                <span v-if="!$v.area_role_id.required">Role/Position is required.</span>
                            </div>
                        </div>

                        <div class="col-md-6" v-if="area_role_id != null" >
                            <span class="text-muted" for="inline-form-input-username">Time Slot</span>
                             <select  v-model="shiftTimeSlot" class="form-select mt-1" :class="{'is-invalid': $v.shiftTimeSlot.id.$error}">
                                <option v-if="shiftsForRole.shift_time_slots.length == 0" disabled> No Time Slot Available</option>
                                <option :value="time_slot" v-for="(time_slot,index) in shiftsForRole.shift_time_slots" :key="index" >{{DateWithMonthName(time_slot.from)}} - {{DateWithMonthName(time_slot.to)}}</option>
                            </select>
                            <div v-if="$v.shiftTimeSlot.id.$error" class="invalid-feedback" >
                                <span v-if="!$v.shiftTimeSlot.id.required">Time Slot is required.</span>
                            </div>
                        </div>
                        <div class="col-md-6"  id="employee___id" v-if="shiftTimeSlot.id != null">
                            <span class="text-muted" for="inline-form-input-name">Employee</span>
                            <vue-multiselect
                                :disabled="fromCalendar"
                                class="form-control p-0"
                                placeholder="Select an Employee"
                                :class="{'is-invalid': $v.employee_id.$error,'bg__disabled': fromCalendar}"
                                v-model="selectedEmployee"
                                hide-selected
                                :load-more="loadMoreEmployees"
                                :loading="employees.loading"
                                :options="employees.data"
                                @on-open="fetchMembersByRole"
                                :show-no-results="true"
                                @on-reaching-end="fetchEmployeesOnReachingEnd"
                                track-by="id"
                                :label="fromCalendar?'name':'label'"
                                :show-labels="false"
                            >
                            </vue-multiselect>
                            <div v-if="$v.employee_id.$error" class="invalid-feedback" >
                                <span v-if="!$v.employee_id.required">Employee is required.</span>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                             <SubmitButton :processing="processing" class="mx-2 w-100" text="Assign" @clicked="checkCounts" />
                             <button type="button" class="btn btn-outline-pink mx-3" @click="assignShiftModal=false; $v.$reset(); fromCalendar=false"> Close </button>
                        </div>
                    </div>
                </b-form>
        </b-modal>
    </div>
</template>
