<script>
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import moment from "moment";
import sidebar from "./sidebar.vue";
import employeeViewCalendar from "@/views/pages/admin/calendar/components/employeeViewCalendar.vue";
import vacantEmployeeViewCalendar from "@/views/pages/admin/dashboard/calendar/vacantEmployeeView.vue";
import assignShiftModal from "@/views/pages/admin/shift/components/assignShiftSlot.vue";
import AlertWidget from "@/components/general/AlertWidget.vue";
import CreateForm from "@/views/pages/admin/shift/components/createForm.vue";

const shift_Prototype = {
  shift_type: "Non_Premium",
  label_color: "#0a0a0a",
  bonus: null,
  name: null,
  interval: null,
  chunk: null,
  from: null,
  to: null,
  location: null,
  desc: null,
  location_id: null,
};

const areas_Prototype = [
  {
    id: null,
    area_id: null,
    roles: null,
    area_role: [
      {
        id: null,
        count: null,
        role_count: null,
        remaining_slots: null,
      },
    ],
  },
];
//User Guide Redirects
const keyToUrlMapping = {
  "CREATE SHIFT": "/create-shift",
  "ADD WORK AREA AND ROLE": "/workarea/create",
  "ADD STAFF TO SHIFTFISH": "/create-team-member",
  "ADD MANAGER TO SHIFTFISH": "/create-team-member",
  "ADD LOCATION TO SHIFTFISH": "/create-location",
};

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    FullCalendar,
    PageHeader,
    CreateForm,
    // DateRangePicker,
    sidebar,
    employeeViewCalendar,
    vacantEmployeeViewCalendar,
    assignShiftModal,
    AlertWidget,
  },
  props: {
    pageTitle:{
      type: String,
      default: null,
    },
    checkTab:{
      type: String,
      default: "calendar",
    }
  },
  

  data() {
    return {
      showQ: false,
      isClick: false,
      calenderRef: {},
      showCalander: false,
      title: "Shifts Schedule",
      items: [
        {
          text: "Schedule",
        },
        {
          text: "Calendar",
          active: true,
        },
      ],
      currentTabComponent: null,
      tabs: [],
      calendarOptions: {
        headerToolbar: {
          left: "prev,title,next",
          // center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        events: [],
        editable: true,
        droppable: true,
        eventResizableFromStart: true,
        dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        customButtons: {
          prev: {
            text: "PREV",
            click: () => {
              this.fetchPrevData();
            },
          },
          next: {
            // this overrides the next button
            text: "Next",
            click: () => {
              this.fetchNextData();
            },
          },
        },
      },
      currentEvents: [],
      showModal: false,
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      renderCalendar: true,
      location: "showAll",
      shifts: [],
      shift_names: ["Breakfast", "Lunch", "Dinner"],
      table_data: { name: null, pagination: 0 },

      // vue Date Picker
      date_range: {
        startDate: null,
        endDate: null,
      },
      area_role: {},
      shift_timeline_copy: [],
      vacant_time_slots: [],
      loading: false,
      viewAs: null,
      sideBarData: {},
      assign_shift: {},
      currentLocation: {},
      currentLocationText: new Date(),
      vacantEmployeeName: null,

      location_id: null,
      createShiftModal: false,
      shiftTimeBreaks: [],
      timebreakshiftfrom: null,
      timebreakshiftto: null,
      processing: false,
      clickedDate: null,
      shiftTo: null,
      shiftFrom: null,
      interval: null,

      areas: JSON.parse(JSON.stringify(areas_Prototype)),
      shift: JSON.parse(JSON.stringify(shift_Prototype)),
      userGuides:[],
    };
  },

  filters: {
    date(val) {
      return val ? moment(val).format("YYYY-MM-DD") : "";
    },
  },
  computed: {
    calculatedWidth() {
      const totalRecords = this.userGuides.length;
      const valueOneCount = this.userGuides.filter(
        (record) => record.value === 1
      ).length;
      if (totalRecords === 0) {
        return 0;
      }
      const widthPerRecord = 100 / totalRecords;
      const width = widthPerRecord * valueOneCount;
      return Math.min(100, width);
    },
  },
  watch: {
    checkTab(newVal){
      this.viewAs = newVal;
    },
    "calenderRef.view": {
      handler: function (newValue) {
        if (newValue?.type == "timeGridDay") {
          this.$refs.sideBar.showCalander = true;
        } else {
          this.$refs.sideBar.showCalander = false;
        }
      },
      deep: true,
    },

    "shift.from"(newVal) {
      if (!this.isInvalid(newVal)) {
        this.shiftFrom = this.clickedDate + "T" + this.shift.from;
        const format1 = "YYYY-MM-DD HH:mm:ss";
        this.interval = this.getDayname(this.shiftFrom);
        this.timebreakshiftfrom = moment(this.shiftFrom).format(format1);
        this.shift.chunk = null;
        this.shift.chunk = 1;
      }
    },

    "shift.to"(newVal) {
      if (!this.isInvalid(newVal)) {
        this.shiftTo = this.clickedDate + "T" + this.shift.to;
        if (this.shiftTo <= this.shiftFrom) {
          this.triggerSwal(
            "shift to time must be greater then from time",
            "error"
          );
        }
        const format1 = "YYYY-MM-DD HH:mm:ss";
        this.timebreakshiftto = moment(this.shiftTo).format(format1);
        this.shift.chunk = 0;
        this.shift.chunk = 1;
      }
   
    },

    "shift.chunk": {
      handler: function (newVal) {
        if (this.shiftFrom && this.shiftTo && newVal != null) {
          this.shiftTimeBreaks = [];
          let intervalDiff = this.hoursTimeDiff(
            this.timebreakshiftfrom,
            this.timebreakshiftto
          );
          let ratio = this.determineRatio(intervalDiff, this.shift.chunk);
          let timeBreak = this.numbertoHours(ratio);
          if (this.shift.chunk > 1) {
            this.addHoursTime(
              this.timebreakshiftfrom,
              timeBreak,
              this.shift.chunk
            );
          } else {
            this.shiftTimeBreaks.push({
              from: this.changeDateTimeToUTCZero(this.shiftFrom),
              to: this.changeDateTimeToUTCZero(this.shiftTo),
              type: "Full",
            });
          }
        }
      },
      deep: true,
    },
    "calenderRef.view.type": {
      handler: function (newValue, oldValue) {
        if (oldValue !== undefined) {
          let getCalendarDates = this.$refs.fullCalendar?.getApi();
          this.table_data.from = this.formatDateYMD(
            getCalendarDates.view.currentStart
          );
          this.table_data.to = this.formatDateYMD(
            getCalendarDates.view.currentEnd
          );
          this.fetchShift();
        }
      },
      immediate: false,
    },

    // "date_range.endDate": {
    //   handler: function (new_data, old_data) {
    //     if (new_data) {
    //       this.table_data.from = this.formatDateYMD(this.date_range.startDate);
    //       this.table_data.to = this.formatDateYMD(new_data);
    //       this.fetchShift();
    //     }
    //   },
    // },
  },

  mounted() {
    this.fetchShift();
    this.fetchGuides();
    this.viewAs=this.checkTab;
  },

  methods: {
    uploadShift() {
      let self = this;
      if (this.$refs.createForm.chunkError === true) {
        self.triggerSwal("Chunks Cannot be greater than 3", "error");
        return;
      }
      this.$refs.createForm.$v.$touch();
      if (this.$refs.createForm.$v.$invalid) {
        self.triggerSwal("Fields Cannot be empty", "error");
        return;
      }
      if (self.areas.length <= 0) {
        self.triggerSwal("Area field is required", "error");
        return;
      }
      for (let i = 0; i < self.areas.length; i++) {
        if (self.areas[i].id == null) {
          self.triggerSwal("Area Fields Cannot be empty", "error");
          return;
        }
        if (self.areas[i].area_role.length <= 0) {
          self.triggerSwal("Role field is required", "error");
          return;
        }
        for (let j = 0; j < self.areas[i].area_role.length; j++) {
          if (
            self.areas[i].area_role[j].id == null ||
            self.areas[i].area_role[j].count == null
          ) {
            self.triggerSwal("Role Fields Cannot be empty", "error");
            return;
          }
        }
      }

      if (self.processing) {
        return;
      }
      self.processing = true;

      self.$axios
        .post("/shift/store", {
          name: self.shift.name,
          desc: self.shift.desc,
          from: self.shiftFrom,
          to: self.shiftTo,
          day: self.interval,
          shift_type: self.shift.shift_type,
          chunk: self.shift.chunk,
          bonus: self.shift.bonus,
          location_id: self.shift.location_id,
          label_color: self.shift.label_color,
          areas: self.areas,
          shifttimebreak: this.shiftTimeBreaks,
          //utc_from/to is to save time in shift area roles.
          utc_from: self.changeDateTimeToUTCZero(self.shiftFrom),
          utc_to: self.changeDateTimeToUTCZero(self.shiftTo),
        })
        .then((response) => {
          let data = response.data;
          self.triggerSwal(data.message, "success");
          this.fetchShift();
          this.hideCreateShiftModal();
        })
        .catch((error) => {
          self.handleErrorResponse(error.response, "error");
        })
        .finally(function () {
          self.processing = false;
        });
    },

    hoursTimeDiff(startTime, endTime) {
      var todayDate = this.getCurrentDate(); //  return in format YYYY-MM-DD
      var startDate = new Date(`${startTime}`);
      var endDate = new Date(`${endTime}`);
      var timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
      var hh = Math.floor(timeDiff / 1000 / 60 / 60);
      hh = ("0" + hh).slice(-2);
      timeDiff -= hh * 1000 * 60 * 60;
      var mm = Math.floor(timeDiff / 1000 / 60);
      mm = ("0" + mm).slice(-2);
      timeDiff -= mm * 1000 * 60;
      var ss = Math.floor(timeDiff / 1000);
      ss = ("0" + ss).slice(-2);
      return { hh: hh, mm: mm, ss: ss };
    },

    determineRatio(intervalDiff, duration) {
      let totalHours =
        intervalDiff.hh + " : " + intervalDiff.mm + " : " + intervalDiff.ss;
      var totalHoursArray = totalHours.split(":");
      var numerator =
        parseInt(totalHoursArray[0]) * 3600 +
        parseInt(totalHoursArray[1]) * 60 +
        parseInt(totalHoursArray[2]);
      var res = numerator / duration;
      return res / 3600;
    },

    numbertoHours(value) {
      var hours = Math.floor(value);
      var minutes = Math.round((value % 1) * 100) / 100;
      return { hours: hours, minutes: minutes };
    },

    addHoursTime(startTime, breakAbleTime, chunks) {
      this.shiftTimeBreaks = [];
      let from = startTime;
      const format1 = "YYYY-MM-DD HH:mm:ss";
      var todayDate = this.getCurrentDate(); //  return in format YYYY-MM-DD
      for (let i = 1; i <= chunks; i++) {
        var dt = new Date(`${from}`);
        dt.setHours(dt.getHours() + breakAbleTime.hours);
        dt.setMinutes(dt.getMinutes() + breakAbleTime.minutes);
        let to = moment(dt).format(format1);
        this.shiftTimeBreaks.push({
          from: this.changeDateTimeToUTCZero(from),
          to: this.changeDateTimeToUTCZero(to),
          type: "Half",
        });
        from = to;
      }
    },

    hideCreateShiftModal() {
      this.$refs.createForm.$v.$reset();
      this.clickedDate = null;
      this.shift = JSON.parse(JSON.stringify(shift_Prototype));
      this.areas = JSON.parse(JSON.stringify(areas_Prototype));
      this.shiftFrom = null;
      this.shiftTo = null;
      this.createShiftModal = false;
    },

    fetchShift() {
      this.loading = true;
      if (this.viewAs == "vacant_shifts") {
        this.table_data.shift_status = "vacant_shifts";
      } else {
        delete this.table_data.shift_status;
      }
      this.$axios
        .get("/shift/index", { params: this.table_data })
        .then((response) => {
          let data = response.data.payload.paginated;
          this.shifts = data;
          this.calendarOptions.events = [];
          this.buildEvents(this.shifts);
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    buildEvents(shifts) {
      this.calendarOptions.events = shifts.map((shift) => {
        let forPremium = "";
        let forVacantShifts = "";
        if (shift.shift_type == "Premium") {
          forPremium = " (" + shift.shift_type + ") *";
        }
        if (shift?.shift_time_slots) {
          shift.shift_time_slots.find((timeslot) => {
            if (timeslot.remaining_slots > 0) {
              return (forVacantShifts = "Vacant Shift - ");
            }
          });
        }

        // if (this.calenderRef?.view?.type == "timeGridDay") {
        //   this.calenderRef?.changeView(
        //     "timeGridDay",
        //     this.changeDateTimeToLocalFormats(shift.shift_time_slots[0].from)
        //   );
        // }

        // if (this.calenderRef?.view?.type == "timeGridWeek") {
        //   this.calenderRef?.changeView(
        //     "timeGridWeek",
        //     this.changeDateTimeToLocalFormats(shift.shift_time_slots[0].from)
        //   );
        // }

        return {
          id: shift.id,
          title: forVacantShifts + shift.name + " " + forPremium,
          start: this.changeFormatTimeCalendarBanner(
            this.changeTimeZone(shift.shift_time_slots[0].from)
          ),
          end: this.changeFormatTimeCalendarBanner(
            this.changeTimeZone(shift.shift_time_slots[0].to)
          ),
          className: "primaryColorBg text-white",
          backgroundColor: shift.label_color,
          shift: shift,
        };
      });
    },

    getRoleName(role_id) {
      let roleName = null;
      this.edit.extendedProps.shift.area_roles.map((role) => {
        if (role_id == role.id) {
          roleName = role.name;
        }
      });
      return roleName;
    },
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
    },

    /**
     * Modal open for add event
     */
    dateClicked(info) {
      this.newEventData = info;
      this.clickedDate = info.dateStr;
      this.createShiftModal = true;
      // this.showModal = true;
    },
    /**
     * Modal open for edit event
     */
    editEvent(info) {
      this.edit = info.event;
      this.shift_timeline_copy = this.edit.extendedProps.shift.shift_timeline;
      if (this.edit.extendedProps.shift.area_roles.length > 0) {
        this.area_role = this.edit.extendedProps.shift.area_roles[0];
        this.fetchSlots(this.edit.extendedProps.shift.id, this.area_role.id);
      } else {
        this.area_role = {};
        this.vacant_time_slots = [];
      }

      this.showModal = true;

      this.adjustCalendarSize();
    },

    adjustCalendarSize() {
      setTimeout(() => {
        this.calenderRef.updateSize();
      });
    },

    closeModal() {
      this.showModal = false;
      this.adjustCalendarSize();
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    submitActions(status, shift) {
      if (shift.status == "Apply For Leave") {
        if (status == "Rejected") {
          status = "Leave Rejected";
        } else {
          status = "Released";
        }
      }
      let self = this;
      self.$axios
        .post("shift/employee/shift-action/" + shift.id, {
          status: status,
          employee_id: shift.employee_id,
          area_role_id: shift.area_role_id,
          shift_id: shift.shift_id,
          slot_id: shift.slot_id,
          action_detail: shift.action_detail,
        })
        .then((response) => {
          let data = response.data;
          this.showModal = false;
          self.triggerSwal(data.message, "success");

          this.shifts.map((shift) => {
            shift.shift_timeline.map((timeline) => {
              if (timeline.id == data.payload.id) {
                timeline.status = data.payload.status;
              }
            });
          });
          this.buildEvents(this.shifts);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(function () { });
    },

    filterByRole() {
      if (Object.keys(this.area_role).length > 0) {
        this.fetchSlots(this.edit.extendedProps.shift.id, this.area_role.id);
        this.edit.extendedProps.shift.shift_timeline = this.shift_timeline_copy;
        this.edit.extendedProps.shift.shift_timeline =
          this.edit.extendedProps.shift.shift_timeline.filter(
            (timeline) => timeline.area_role_id == this.area_role.id
          );
      }
    },

    fetchSlots(shift_id, role_id) {
      this.$axios
        .get("shift-time-slots/area-role", {
          params: {
            shift_id: shift_id,
            area_role_id: role_id,
            empty_slot: true,
          },
        })
        .then((response) => {
          this.vacant_time_slots = response.data.payload;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    changeViewAs(paramsViewAs) {
       this.$refs.sideBar.sideBarData = {
        location: null,
        shift_name: null,
        area: {},
        employee: {},
        role: {},
        availability: null,
        shift_status: null,
        vacantEmployeeName: null,
      };
      // this.viewAs = paramsViewAs;
      
    },

    listenFromSideBar(newData) {

      if (newData?.shift_name) {
        if (newData?.shift_name == "All") {
          this.table_data.name = null;
        } else {
          this.table_data.name = newData?.shift_name;
        }
      }

      if (newData?.location) {
        if (newData?.location == "showAll") {
          this.currentLocation = {};
          this.table_data.location_id = null;
          this.currentLocationText = new Date();
        } else {
          this.table_data.location_id = newData.location.id;
          this.currentLocation = newData.location.time_zone;
          this.currentLocationText = newData?.location?.time_zone?.text;
        }
      }

      if (newData?.area) {
        this.table_data.area_id = newData?.area?.id;
      }

      if (newData?.endDate) {
        this.table_data.from = newData.startDate;
        this.table_data.to = newData.endDate;
        if (this.calenderRef.view.type == "timeGridDay") {
          this.calenderRef.gotoDate(newData.startDate);
        }
      }

      if (newData?.employee) {
        this.table_data.employee_id = newData?.employee?.employee?.id;
      }

      if (newData?.role) {
        this.table_data.area_role_id = newData?.role?.id;
      }

      if (newData?.availability) {
        if (newData?.availability == "All") {
          this.table_data.availability = null;
        } else {
          this.table_data.availability = newData.availability;
        }
      }

      if (newData?.shift_status) {
        if (newData?.shift_status == "All") {
          this.table_data.shift_status = null;
        } else {
          this.table_data.shift_status = newData.shift_status;
        }
      }

      if (newData?.clearFilters) {
        this.table_data = {
          name: null,
          pagination: 0,
        };
        this.currentLocationText = new Date();
        let view = this.calenderRef.view;
        this.table_data.from = this.formatDateYMD(view.currentStart);
        this.table_data.to = this.formatDateYMD(view.currentEnd);
      }

      if (newData != null) {
        this.fetchShift();
      }
    },

    setCalender() {
      this.calenderRef = this.$refs.fullCalendar?.getApi();
      let view = this.calenderRef.view;
      this.table_data.from = this.formatDateYMD(view.currentStart);
      this.table_data.to = this.formatDateYMD(view.currentEnd);
      // this.fetchShift();
    },

    fetchNextData() {
      this.calenderRef.next();

      let view = this.calenderRef.view;
      this.table_data.from = this.formatDateYMD(view.currentStart);
      this.table_data.to = this.formatDateYMD(view.currentEnd);
      this.fetchShift();
    },
    fetchPrevData() {
      this.calenderRef.prev();
      let view = this.calenderRef.view;
      this.table_data.from = this.formatDateYMD(view.currentStart);
      this.table_data.to = this.formatDateYMD(view.currentEnd);
      this.fetchShift();
    },

    changeTimeZone(date) {
      if (Object.entries(this.currentLocation).length > 0) {
        let zone = this.currentLocation.utc[0];
        return this.changeDateTimeTimeZone(date, zone);
      } else {
        return this.DateWithMonthName(date, "DD-MM-YYYY hh:mm A");
      }
    },
    //ForVacantShifts

    searchVacantEmployee(searchItem) {
      this.vacantEmployeeName = searchItem;
    },
    //User Guide Related  Methods
    fetchGuides() {
      this.$axios
        .get("/guides")
        .then((response) => {
          this.userGuides = response.data.payload;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    convertToFirstUppercase(inputString) {
      const lowercaseString = inputString.toLowerCase();
      const firstUppercase = lowercaseString.charAt(0).toUpperCase();
      const convertedString = firstUppercase + lowercaseString.slice(1);
      return convertedString;
    },
    updateGuide(id) {
      let guide = this.userGuides.filter((g) => g.id == id);
      this.$axios
        .post("guides/update", guide)
        .then((response) => {
          this.userGuides = response.data.payload;
        })
        .catch((error) => {
          console.log(error);
          const index = this.userGuides.findIndex((record) => record.id === id);
          if (index >= 0) {
            this.userGuides[index].value = 0;
          }
        })
        .finally(() => {
          this.$router.push(keyToUrlMapping[guide[0].key]);
        });
    },
    showSideBar() {
      if (this.viewAs == "calendar" || this.viewAs == "vacant_shifts") {
        return true;
      } else {
        this.isClick = true;
        return false;
      }
    },
  },

  middleware: "authentication",
};
</script>

<template>
  <div class="row">
    <div v-show="showSideBar()" class="col-xl-2 ps-0 position-relative transition_class" :class="{ fullWidth: isClick }">
      <sidebar ref="sideBar" @filterData="listenFromSideBar" @searchVacantEmployee="searchVacantEmployee" />
    </div>
    <div class="col-xl-10" :class="{ 'col-xl-12': isClick }">
      <PageHeader :title="title" :items="items" v-if="pageTitle" />

      <APILoader v-if="loading" :loading="loading" class="align-middle"></APILoader>
      <div>
        <h6 class="text-end text-pink">Timezone : {{ currentLocationText }}</h6>
        <div class="card" v-if="viewAs === 'calendar' && viewAs !== 'vacant_shifts'">
          <div class="card-body under-tabs">
            <div class="row">
              <div class="col-lg-12" :class="{
                'col-xxl-8 col-xl-6': showModal == true,
                'col-xxl-12 col-xl-12': showModal == false,
              }">
                <div class="tab-content position-relative" id="adminCalendar">
                  <div class="profile__completeion">
                    <div class="complete__steps position-relative">
                      <i class="fa fa-question question_icon user-select-none" @click="showQ = !showQ"></i>

                      <div class="step__card card p-3 user-select-none" v-if="showQ">
                        <h4>Hi {{ currentUser.name }}</h4>

                        <p>
                          Complete the following steps to learn how shiftfish
                          works and hit the ground running.
                        </p>
                        <div class="progress__bar">
                          <div class="fill__bar" :style="{ width: calculatedWidth + '%' }"></div>
                        </div>
                        <div v-for="guide in userGuides" :key="guide.id" class="form-check">
                          <b-form-checkbox v-model="guide.value" :value="1" :unchecked-value="0" class="mb-3" checked
                            @change="updateGuide(guide.id)" :disabled="guide.value == 1" plain>
                            {{ convertToFirstUppercase(guide.key) }}
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FullCalendar class="vue-calendar-ui" v-if="renderCalendar" ref="fullCalendar"
                    :options="calendarOptions" @hook:mounted="setCalender"></FullCalendar>
                </div>
              </div>
              <div v-if="showModal" class="col-xxl-4 col-xl-6 col-lg-12">
                <div v-if="edit.extendedProps != null" class="mt-md-0 mt-0">
                  <div class="cross-ico text-end mb-2">
                    <button @click="closeModal()" class="btn py-1 px-2 btn-outline-pink mx-3">
                      <i class="fas fa-arrow-right"></i>
                    </button>
                  </div>
                  <div
                    class="d-flex justify-content-between gap-2 align-items-center shadow bg-dark-pink radius-10 px-3 py-2 shadow">
                    <div class="shiftname">
                      <h3 class="f-18 mb-0 pb-0 text-white">
                        {{ edit.extendedProps.shift.name }}
                      </h3>
                    </div>
                    <div class="shift-role">
                      <select @change="filterByRole()" v-model="area_role"
                        class="form-select form-select-sm border-2 border-white ps-3 pe-5 rounded-pill f-12"
                        aria-label="Default select example">
                        <option class="text-dark" :value="area_role" v-for="(area_role, role_index) in edit.extendedProps
                          .shift.area_roles" :key="role_index">
                          {{ getSafe(() => area_role.name) }}
                        </option>
                        <option class="text-dark" :value="area_role"
                          v-if="edit.extendedProps.shift.area_roles.length == 0" disabled selected>
                          Unassigned Role
                        </option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div class="my-3 calendar-tabs">
                      <b-tabs content-class="mt-3" fill>
                        <b-tab title="Vacant" class="text-dark" active>
                          <div class="position-relative" v-for="(timeslot, index) in vacant_time_slots" :key="index">
                            <div class="user-box d-flex justify-content-between align-items-center py-3 px-3 rounded">
                              <div class="user-info d-flex gap-3">
                                <div>
                                  <i class="fa fa-arrow-right"></i>
                                </div>
                                <div class="user-name w-100">
                                  <p class="pb-0 mb-0 f-12 text-muted">
                                    Time:
                                    {{
                                      getSafe(() =>
                                        changeTimeZone(timeslot.from)
                                      )
                                    }}
                                    -
                                    {{
                                      getSafe(() => changeTimeZone(timeslot.to))
                                    }}
                                  </p>
                                  <div class="d-flex my-2">
                                    <p class="f-12 w-50 my-0 mx-0">
                                      Remaining Count :
                                      {{
                                        getSafe(() => timeslot.remaining_slots)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex mb-2 justify-content-end">
                              <button class="btn btn-pink btn-sm rounded" v-b-modal.modal-assignShift
                                @click="assign_shift = edit.extendedProps.shift">
                                Assign Shift
                              </button>
                            </div>
                            <div class="cl-hr-line"></div>
                          </div>
                        </b-tab>

                        <b-tab title="Schedule">
                          <div v-if="edit.extendedProps.shift.shift_timeline.length > 0
                            ">
                            <div class="position-relative" v-for="(timeline, index) in edit.extendedProps
                              .shift.shift_timeline" :key="index">
                              <div class="user-box d-flex justify-content-between align-items-center py-3 px-3 rounded">
                                <div class="user-info d-flex gap-3">
                                  <div class="user-img w-25">
                                    <img :src="timeline.employee.user.avatar_path" class="user-img-cl" alt="user image" />
                                  </div>
                                  <div class="user-name w-100">
                                    <h3 class="f-14 mb-0 pb-0 poppins">
                                      {{
                                        getSafe(
                                          () => timeline.employee.user.name
                                        )
                                      }}
                                    </h3>
                                    <p class="pb-0 mb-0 f-12 text-muted">
                                      <!-- {{ currentLocation }} -->
                                      {{
                                        getSafe(() =>
                                          changeTimeZone(timeline.from)
                                        )
                                      }}
                                      -
                                      {{
                                        getSafe(() =>
                                          changeTimeZone(timeline.to)
                                        )
                                      }}<span class="ms-2">{{
  timeline.status
}}</span>
                                    </p>
                                    <p class="pb-0 mb-0 f-12 text-muted">
                                      {{
                                        getSafe(() =>
                                          getRoleName(timeline.area_role_id)
                                        )
                                      }}
                                    </p>
                                  </div>
                                </div>

                                <div class="shift-menu">
                                  <b-dropdown v-if="timeline.status != 'Accepted' &&
                                      timeline.status != 'Released' &&
                                      timeline.status != 'Rejected'
                                      " id="dropdown-right" right html="<i class='fas fa-ellipsis-v'></i>"
                                    variant="white">
                                    <b-dropdown-item class="f-12" @click="
                                      submitActions('Accepted', timeline)
                                      "><i class="fas fa-check-square me-2"></i>Accept</b-dropdown-item>
                                    <b-dropdown-item class="f-12" @click="
                                      submitActions('Rejected', timeline)
                                      "><i class="fas fa-times-circle me-2"></i>
                                      Reject</b-dropdown-item>
                                  </b-dropdown>
                                  <div class="text-center text-muted" v-else>
                                    Approved
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="cl-hr-line"></div>
                          </div>
                          <AlertWidget text="No shift scheduled" v-else></AlertWidget>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </div>

                  <div class="bg-light radius-10">
                    <div class="d-flex gap-2 align-items-center justify-content-between px-3 py-3">
                      <div class="shift-time">
                        <p class="f-12 mb-0">
                          <span class="d-block fw-semibold text-dark">From</span>
                          <span class="d-block text-muted">{{
                            changeTimeZone(
                              edit.extendedProps.shift.shift_time_slots[0].from
                            )
                          }}</span>
                        </p>
                      </div>
                      <div class="shift-date">
                        <p class="f-12 mb-0">
                          <span class="d-block fw-semibold text-dark">To</span>
                          <span class="d-block text-muted">{{
                            changeTimeZone(
                              edit.extendedProps.shift.shift_time_slots[0].to
                            )
                          }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="px-3 pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="interval">
                          <span class="f-12 fw-bold d-block">Required Count of {{ area_role.name }}:</span>
                          <span class="f-12 d-block text-muted">{{
                            getSafe(() => area_role.pivot.role_count)
                          }}</span>
                        </div>
                        <div class="type-of-shift">
                          <span class="badge bg-timecolor rounded-0 text-dark">{{ edit.extendedProps.shift.shift_type
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <employeeViewCalendar :area_id="table_data.area_id" :name="table_data.name" :employee_id="table_data.employee_id"
          :area_role_id="table_data.area_role_id" :availability="table_data.availability"
          v-else-if="viewAs == 'employee'" />
        <vacantEmployeeViewCalendar :area_id="table_data.area_id" :name="table_data.name"
          :employee_id="table_data.employee_id" :area_role_id="table_data.area_role_id"
          :availability="table_data.availability" :searchAbleDataProp="vacantEmployeeName"
          v-else-if="viewAs == 'vacant_employee'" />
        <b-modal v-model="createShiftModal" size="lg" title="Create Shift" title-class="text-black font-18"
          body-class="p-3" hide-footer no-close-on-backdrop no-close-on-esc hide-header-close>
          <create-form :areas="areas" :location_id="location_id" :shift="shift" ref="createForm"
            :shiftFromCalendar="true"></create-form>
          <SubmitButton text="Create Shift" @clicked="uploadShift" :processing="processing"></SubmitButton>
          <button type="button" class="btn btn-outline-pink mx-3" @click="hideCreateShiftModal">
            Cancel
          </button>
        </b-modal>
      </div>

      <assignShiftModal ref="assignShift" :shift="assign_shift" :area_role="area_role" :currentLocation="currentLocation">
      </assignShiftModal>
    </div>
  </div>
</template>