<template>
    <div>
      <div class="table-responsive">
        <table class="table table-bordered align-baseline text-center">
          <thead>
            <tr>
              <th scope="col" class="text-center bg-light">
                <button @click="$emit('changeSlider', 'previous')" class="btn">
                  <i class="fas fa-chevron-left"></i>
                </button>
                {{ startDate }} - {{ endDate }}
                <button @click="$emit('changeSlider', 'next')" class="btn">
                  <i class="fas fa-chevron-right"></i>
                </button>
              </th>
              <th
                v-for="(week, index) in weeks"
                scope="col"
                class="text-center bg-light"
                :key="index"
              >
                {{ week.date }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="employee in allEmployees" :key="employee.id">
              <td class="d-flex gap-2 align-items-center" align="center" >
                <span>
                  <img
                    :src="employee.avatar_path"
                    alt
                    width="42"
                    height="42"
                    class="img-fluid rounded-circle"
                  />
                </span>
                <span>{{ employee.name }}</span>
              </td>
              <!-- <template v-for="(timeline, key) in timelineKey"> -->
              <td
                :class="['cursor-pointer p-0' , {'active primary-bg': getDataFromTimeline(
                      week.date,
                      getSafe(() => employee.shifts, [])
                    ) != null }]"
                v-for="(week, index) in weeks"
                :key="index"
              >
                <span 

                  v-if="
                    getDataFromTimeline(
                      week.date,
                      getSafe(() => employee.shifts, [])
                    ) != null
                  "
                  @click="assignShift(employee,getDataFromTimeline(
                      week.date,
                      getSafe(() => employee.shifts, [])
                    ))"       
                >
                Assign Shift
                </span>
              </td>
            </tr>
            <tr v-if="allEmployees.length == 0">
              <td colspan="12">
               <AlertWidget ></AlertWidget>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <pagination :paginationProp="paginationData" @next="fetchVacantEmployee" />
              </td>
            </tr>
          </tbody>
        </table>
        <assignShiftModal  ref="assignShift" id="modal-vacant-shift-week" :shift="assign_shift"> </assignShiftModal>
      </div>
    </div>
  </template>
  
  <script>
  import AlertWidget from "@/components/general/AlertWidget.vue";
  import assignShiftModal from "@/views/pages/admin/shift/components/assignShift.vue";
  import timeSlot from "@/mixins/timeSlot";
  import pagination from "@/views/pages/admin/calendar/reuseableComponents/pagination.vue";
  export default {
    name: "VacntEmployeeWeekView",
    props: {
      weeks: {
        type: [Array],
        default: () => {},
      },
      startDate: {
        type: [String],
        default: () => {},
      },
      endDate: {
        type: [String],
        default: () => {},
      },
      searchAbleDataProp:{
        type:String,
        default:null,
      }
    },
    components: { AlertWidget,assignShiftModal , pagination },
    data() {
      return {
        allEmployees: [],
        timeSlots: [],
        selectedUserId: null,
        shiftId: null,
        processing: false,
        paginationData:null,
        assign_shift:{}
      };
    },
  
    watch: {
      allEmployees: function (newVal) {
        this.allEmployees = newVal;
      },
      startDate:function(){
       this.fetchVacantEmployee(); 
      },
      searchAbleDataProp:function(newVal)
      { 
           this.fetchVacantEmployee();
      }
    },
  
    mounted() {
      this.fetchVacantEmployee();
    },
  
    methods: {
      async fetchVacantEmployee( url = "/shift/employee/get-vacant-employees") {
        try {
          let response = await this.$axios.get(
            url,
            {
              params: {
                from: this.customFormatDate(this.startDate, "DD-MM-YYYY"),
                to: this.customFormatDate(this.endDate, "DD-MM-YYYY"),
                searchAbleName: this.searchAbleDataProp,
              },
            }
          );
          this.allEmployees = response.data.payload.data;
          this.paginationData = response.data.payload
        } catch (error) {
          console.log(error);
        }
      },
      
      assignShift(user , shift) {
          this.$bvModal.show('modal-vacant-shift-week');
          this.assign_shift = shift;
          this.$refs.assignShift.fromCalendar=true;
          this.$refs.assignShift.selectedEmployee=user;
      },
    },
    mixins: [timeSlot],
  };
  </script>